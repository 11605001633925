/*!
 * Bootstrap Grid v4.4.0 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 *
 * Compiled using Bootstrap Less Port v2.3.0 (https://github.com/seanCodes/bootstrap-less-port)
 * A port of Bootstrap’s Sass source code to Less.
 * Copyright 2017–2020 Sean Juarez
 * Licensed under MIT (https://github.com/seanCodes/bootstrap-less-port/blob/master/LICENSE)
 */

html {
	box-sizing: border-box;
	-ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
	box-sizing: inherit;
}

@import "_functions";
@import "_variables";

@import "mixins/_breakpoints";
@import "mixins/_grid-framework";
@import "mixins/_grid";

@import "_grid";
@import "utilities/_display";
@import "utilities/_flex";
@import "utilities/_spacing";
